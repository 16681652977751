<template>
  <div class="tabs">
    <el-tag
      v-for="(item, index) in tags"
      :key="item.Path"
      :closable="item.Name !== 'home'"
      :effect="$route.name === item.Name ? 'dark' : 'plain'"
      @click="changeMenu(item)"
      @close="handleClose(item, index)">
      {{ item.Name }}
    </el-tag>
  </div>
</template>

<script>
export default {
  name: 'CommonTag',
  data () {
    return {}
  },
  computed: {
    tags () {
      return this.$store.state.tab.tabList
    }
  },
  methods: {
    // 点击tag标签进行页面跳转
    changeMenu (item) {
      this.$router.push({ path: item.Path })
    },

    // 删除tag标签
    handleClose (item, index) {
      if (item.Path === '/SystemHome' || item.Path === '/') return
      // 调用vux里的删除方法
      this.$store.commit('closeTag', item)
      if (this.$route.path === '/SystemHome' || this.$route.path === '/') return
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="less" scoped>
.tabs {
  padding: 0 1vw 0 1vw;

  .el-tag {
    margin: 1vw 1vw 0 0;
    user-select: none;
    cursor: pointer;
  }
}
</style>
