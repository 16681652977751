<template>
  <div class="menu">
    <div class="parent-menu" v-for="(parent, index) in menuData" :key="index">
      <div class="parent-menu-item" @click="clickMenu(parent)">
        <div class="parent-menu-item-title">
          <i :class="`el-icon-${parent.Icon}`"/>
          <p>{{ parent.Name }}</p>
          <i class="el-icon-arrow-down"/>
        </div>
      </div>
      <transition name="slide-fade">
        <div v-show="parent.Status && parent.Status !== 1" style="width: 100%;">
          <div class="son-menu" v-for="(son, indexs) in parent.Children" :key="indexs">
            <div class="son-menu-item" @click="skipPath(son)">
              <div class="son-menu-item-title">
                <i :class="`el-icon-${son.Icon}`"/>
                <p>{{ son.Name }}</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>

export default {
  name: 'McMenu',
  props: {},
  data () {
    return {
      menuData: []
    }
  },
  created: async function () {
    await this.init()
  },
  methods: {
    init: async function () {
      const menuSession = sessionStorage.getItem('menu')
      if (menuSession != null) {
        this.menuData = JSON.parse(menuSession)
        return
      }
      sessionStorage.removeItem('journal')
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('journalId')
      this.$router.push('/login')
    },

    clickMenu: function (item) {
      console.log(item)
      if (item.Status === 1) {
        item.Status = true
      } else {
        item.Status = !item.Status
      }
    },
    skipPath: function (item) {
      console.log(item)
      if (this.$route.path !== item.Path) this.$router.push(item.Path)
      this.$store.commit('selectMenu', item)
    }
  }
}
</script>

<style scoped lang="less">
ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu {
  width: 100%;
  background-color: rgb(84, 92, 100);
  font-size: 0.8vw;
  user-select: none;
  font-weight: 400;
}

.parent-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-bottom: 0.1vw solid #717171;
}

.parent-menu:first-child {
  border-top: 0.1vw solid #717171;
}

.parent-menu-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.parent-menu-item:hover {
  background-color: rgb(67, 74, 80);
}

.parent-menu-item-title {
  height: 3vw;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.parent-menu-item-title > i {
  color: #909399;
}

.parent-menu-item-title > p {
  width: 50%;
  text-align: center;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 不换行 */
  color: #F5F5F5;
}

.son-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.son-menu-item {
  width: 100%;
  height: 3vw;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.son-menu-item:hover {
  background-color: rgb(67, 74, 80);
}

.son-menu-item-title {
  height: 3vw;
  width: 70%;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.son-menu-item-title > i {
  color: #909399;
}

.son-menu-item-title > p {
  width: 86%;
  text-align: center;
  overflow: hidden; /* 超出部分隐藏 */
  white-space: nowrap; /* 不换行 */
  color: #F5F5F5;
}

.slide-fade-enter-active {
  transition: all 0.6s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

</style>
